import Vue from 'vue';
import CustomVueRouter from './customVueRouter';

import MarketPicker from '../views/LanguagePickerView';

import Home from '../views/Home';
import HomeCompany from '../views/HomeCompany';
import HomeGoodiebag from '../views/HomeGoodiebag';
import HomeOffer from '../views/HomeOffer';

import HomeInfo from '../views/HomeInfo';
import HomeData from '../views/HomeData';
import HomeDataCookies from '../views/HomeDataCookies';
import HomeDataVisitors from '../views/HomeDataVisitors';
import HomeDataMembers from '../views/HomeDataMembers';
import HomeDataParticipants from '../views/HomeDataParticipants';
import HomeDataTerms from '../views/HomeDataTerms';
import HomeDataCompanies from '../views/HomeDataCompanies';

import HomeEmail from '../views/HomeEmail';
import HomeClose from '../views/HomeClose';
import HomeFAQ from '../views/HomeFAQ'
import Home429 from '../views/Home429';

import UserStart from '../views/UserStart';
import UserCompany from '../views/UserCompany';
import UserGoodiebag from '../views/UserGoodiebag';
import UserOffer from '../views/UserOffer';
import UserPV from '../views/UserPV';

import UserProfile from '../views/UserProfile';
import UserSettings from '../views/UserSettings';
import UserClubProfile from '../views/UserClubProfile';

import UserClubProfileMyProducts from '../views/UserClubProfileMyProducts';
import UserClubProfileMyFavorites from '../views/UserClubProfileMyFavorites';
import UserClubProfileMyHistory from '../views/UserClubProfileMyHistory';
import UserClubProfileAbout from '../views/UserClubProfileAbout';

/*import UserDevices from '../views/UserDevices';
import UserDevice from '../views/UserDevice';*/
import UserEmail from '../views/UserEmail';
import UserSharedData from '../views/UserSharedData';
import UserActivations from '../views/UserActivations';
import UserHistory from '../views/UserHistory';

// Games
import QrHuntHome from '../games/QrHunt/QrHuntHome';
import QrHuntOnboarding from '../games/QrHunt/QrHuntOnboarding';
import QrHuntError from '../games/QrHunt/QrHuntError';

// Admin
// New admin
import AdminHome from '../views/AdminHome';
import AdminArchived from '../views/AdminArchived';
import AdminDrafts from '../views/AdminDrafts';
import AdminDashboard from '../views/AdminDashboard';
import AdminParentCompanies from '../views/AdminParentCompanies';
import AdminParentCompany from '../views/AdminParentCompany';
import AdminCompanies from '../views/AdminCompanies';
import AdminCompany from '../views/AdminCompany';
import AdminCompanyContentCreator from '../views/AdminCompanyContentCreator';
import AdminGoodiebags from '../views/AdminGoodiebags';
import AdminGoodiebag from '../views/AdminGoodiebag';
import AdminOffers from '../views/AdminOffers';
import AdminOffer from '../views/AdminOffer';
import AdminUsers from '../views/AdminUsers';
import AdminUser from '../views/AdminUser';
import AdminHyperlink from '../views/AdminHyperlink';
import AdminHyperlinks from '../views/AdminHyperlinks';
import AdminLinksManager from '../views/AdminLinksManager';
import AdminLinksManagerCompany from '../views/AdminLinksManagerCompany';
import AdminLinksManagerGoodiebag from '../views/AdminLinksManagerGoodiebag';
import AdminLinksManagerOffer from '../views/AdminLinksManagerOffer';

import AdminPVHome from '../views/AdminPVHome';
import AdminPVCompanies from '../views/AdminPVCompanies';
import AdminPVCompany from '../views/AdminPVCompany';
import AdminPVCampaigns from '../views/AdminPVCampaigns';
import AdminPVCampaign from '../views/AdminPVCampaign';
import AdminPVActivations from '../views/AdminPVActivations';
import AdminPVActivation from '../views/AdminPVActivation';
import AdminPVReceipts from '../views/AdminPVReceipts';
import AdminPVReceipt from '../views/AdminPVReceipt';
import AdminPVReviews from '../views/AdminPVReviews';
import AdminPVChains from '../views/AdminPVChains';
import AdminPVChain from '../views/AdminPVChain';
import AdminPVRetailers from '../views/AdminPVRetailers';
import AdminPVRetailer from '../views/AdminPVRetailer';
import AdminPVStores from '../views/AdminPVStores';
import AdminPVStore from '../views/AdminPVStore';
import AdminPVFlagged from '../views/AdminPVFlagged';
import AdminPVBlocked from '../views/AdminPVBlocked';

import AdminGamePicker from '../views/AdminGamePicker';
import AdminGame from '../views/AdminGame';
import AdminGames from '../views/AdminGames';

// Partner
import CompanyHome from '../views/CompanyHome';
import CompanyLink from '../views/CompanyLink';
import CompanyCompany from '../views/CompanyCompany';
import CompanyGoodiebag from '../views/CompanyGoodiebag';
import CompanyCollection from '../views/CompanyCollection';
import CompanyActivation from '../views/CompanyActivation';
import CompanyCollections from '../views/CompanyCollections';
import CompanyOffer from '../views/CompanyOffer';
import CompanyDrafts from '../views/CompanyDrafts';
import CompanyArchived from '../views/CompanyArchived';
import CompanyDashboard from '../views/CompanyDashboard';
import CompanyDashboardCollection from '../views/CompanyDashboardCollection'
import CompanyDashboardActivation from '../views/CompanyDashboardActivation';
import CompanySettings from '../views/CompanySettings';
import CompanySupport from '../views/CompanySupport';
import CompanyUser from '../views/CompanyUser';
import CompanyUsers from '../views/CompanyUsers';

Vue.use(CustomVueRouter);

const prefixRoutes = (prefix, routes) => routes.map((route) => {
  route.path = prefix + route.path;
  return route;
});

const router =  new CustomVueRouter({
  mode: 'history',
  // scrollBehavior (to, from, savedPosition) {
  scrollBehavior(to, from) {
    // Skip if destination full path has query parameters and differs in no other way from previous
    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
    }

    return { x: 0, y: 0 }
  },

  routes: [
    {
      path: '/',
      name: 'MarketPicker',
      component: MarketPicker,
    },
  ].concat(prefixRoutes(`/:market`, [
    // Home
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/company/:companyId',
      redirect: { name: 'HomeCompany' },
    },
    {
      path: '/e/:companyId',
      name: 'HomeCompany',
      component: HomeCompany,
      props: true,
    },
    {
      path: '/goodiebag/:goodiebagId',
      redirect: { name: 'HomeGoodiebag' },
    },
    {
      path: '/c/:goodiebagId',
      name: 'HomeGoodiebag',
      component: HomeGoodiebag,
      props: true,
    },
    {
      path: '/offer/:offerId',
      redirect: { name: 'HomeOffer' },
    },
    {
      path: '/a/:offerId',
      name: 'HomeOffer',
      component: HomeOffer,
      props: true,
    },
    {
      path: '/info',
      name: 'HomeInfo',
      component: HomeInfo,
      children: [
        {
          path: 'company',
          // component: UserProfileSettings,
        },
      ]
    },
    {
      path: '/data',
      name: 'HomeData',
      component: HomeData,
    },
    {
      name: 'HomeData.cookies',
      path: '/data/cookies',
      component: HomeDataCookies,
    },
    {
      name: 'HomeData.visitors',
      path: '/data/visitors',
      component: HomeDataVisitors,
    },
    {
      name: 'HomeData.members',
      path: '/data/members',
      component: HomeDataMembers,
    },
    {
      name: 'HomeData.participants',
      path: '/data/participants',
      component: HomeDataParticipants,
    },
    {
      name: 'HomeData.terms',
      path: '/data/terms',
      component: HomeDataTerms,
    },
    {
      name: 'HomeData.companies',
      path: '/data/companies',
      component: HomeDataCompanies,
    },

    {
      path: '/faq/:companyId',
      name: 'HomeFAQ',
      component: HomeFAQ,
      props: true,
    },
    {
      path: '/email',
      name: 'HomeEmail',
      component: HomeEmail,
    },
    {
      path: '/close',
      name: 'HomeClose',
      component: HomeClose,
    },
    {
      path: '/429',
      name: 'Home429',
      component: Home429,
    },

    // Games
    // QrHunt
    {
      path: '/g/qr-hunt/:gameId',
      name: 'QrHuntHome',
      component: QrHuntHome,
      props: true,
    },
    {
      path: '/g/qr-hunt/:gameId/onboarding',
      name: 'QrHuntOnboarding',
      component: QrHuntOnboarding,
      props: true,
    },
    {
      path: '/g/qr-hunt/:gameId/error',
      name: 'QrHuntError',
      component: QrHuntError,
      props: true,
    },

    // Consumer views
    {
      path: '/u-start',
      redirect: { name: 'UserStart' },
    },
    {
      path: '/u-st',
      name: 'UserStart',
      component: UserStart,
    },
    {
      path: '/u-company/:companyId',
      redirect: { name: 'UserStart' },
    },
    {
      path: '/u-e/:companyId',
      name: 'UserCompany',
      component: UserCompany,
      props: true,
    },
    {
      path: '/u-goodiebag/:goodiebagId',
      redirect: { name: 'UserGoodiebag' },
    },
    {
      path: '/u-c/:goodiebagId',
      name: 'UserGoodiebag',
      component: UserGoodiebag,
      props: true,
    },
    {
      path: '/u-offer/:offerId',
      redirect: { name: 'UserOffer' },
    },
    {
      path: '/u-a/:offerId',
      name: 'UserOffer',
      component: UserOffer,
      props: true,
    },

    // Purchase validation
    {
      path: '/pv/:pvId/:previousOfferId',
      name: 'UserPV',
      component: UserPV,
      props: true,
    },

    {
      path: '/u-profile',
      redirect: { name: 'UserProfile' },
    },
    {
      path: '/u-p',
      name: 'UserProfile',
      component: UserProfile,
      children: [
        {
          path: 'u-shared',
          name: 'UserSharedData',
          component: UserSharedData,
        },
        {
          path: 'u-activations',
          name: 'UserActivations',
          component: UserActivations,
        },
        {
          path: 'u-history',
          name: 'UserHistory',
          component: UserHistory,
        }
      ]
    },
    {
      path: '/u-settings',
      redirect: { name: 'UserSettings' },
    },
    {
      path: '/u-set',
      name: 'UserSettings',
      component: UserSettings,
    },
    {
      path: '/u-p/club/:companyId',
      name: 'UserClubProfile',
      component: UserClubProfile,
      props: true,
      children: [
        {
          path: 'products',
          name: 'UserClubMyProducts',
          props: true,
          component: UserClubProfileMyProducts,
        },
        {
          path: 'favorites',
          name: 'UserClubMyFavorites',
          props: true,
          component: UserClubProfileMyFavorites,
        },
        {
          path: 'history',
          name: 'UserClubMyHistory',
          props: true,
          component: UserClubProfileMyHistory,
        },
        {
          path: 'about',
          name: 'UserClubAbout',
          props: true,
          component: UserClubProfileAbout,
        }
      ]
    },
    /*{
      path: '/u-devices',
      name: 'UserDevices',
      component: UserDevices,
    },
    {
      path: '/u-devices/:deviceName',
      name: 'UserDevice',
      component: UserDevice,
      props: true
    },*/
    {
      path: '/u-email',
      name: 'UserEmail',
      component: UserEmail,
    },

    // Admin views
    // New admin
    {
      path: '/admin',
      name: 'AdminHome',
      component: AdminHome,
    },
    {
      path: '/admin/archived',
      name: 'AdminArchived',
      component: AdminArchived,
    },
    {
      path: '/admin/draft',
      name: 'AdminDrafts',
      component: AdminDrafts,
    },
    {
      path: '/admin/dashboard',
      name: 'AdminDashboard',
      component: AdminDashboard,
    },
    {
      path: '/admin/parent-companies',
      name: 'AdminParentCompanies',
      component: AdminParentCompanies,
    },
    {
      path: '/admin/parent-companies/:parentCompanyId',
      name: 'AdminParentCompany',
      component: AdminParentCompany,
      props: true,
    },
    {
      path: '/admin/companies',
      name: 'AdminCompanies',
      component: AdminCompanies,
    },
    {
      path: '/admin/companies/:companyId',
      name: 'AdminCompany',
      component: AdminCompany,
      props: true,
    },
    {
      path: '/admin/companies/:companyId/contentcreator',
      name: 'AdminCompanyContentCreator',
      component: AdminCompanyContentCreator,
      props: true,
    },
    {
      path: '/admin/goodiebags',
      name: 'AdminGoodiebags',
      component: AdminGoodiebags
    },
    {
      path: '/admin/goodiebags/:goodiebagId',
      name: 'AdminGoodiebag',
      component: AdminGoodiebag,
      props: true,
    },
    {
      path: '/admin/games',
      name: 'AdminGamePicker',
      component: AdminGamePicker,
    },
    {
      path: '/admin/games/:gameType',
      name: 'AdminGames',
      component: AdminGames,
      props: true,
    },
    {
      path: '/admin/games/:gameType/:gameId',
      name: 'AdminGame',
      component: AdminGame,
      props: true
    },
    {
      path: '/admin/offers',
      name: 'AdminOffers',
      component: AdminOffers
    },
    {
      path: '/admin/offers/:offerId',
      name: 'AdminOffer',
      component: AdminOffer,
      props: true,
    },
    {
      path: '/admin/users',
      name: 'AdminUsers',
      component: AdminUsers,
    },
    {
      path: '/admin/users/:userId',
      name: 'AdminUser',
      component: AdminUser,
      props: true,
    },
    {
      path: '/admin/hyperlinks',
      name: 'AdminHyperlinks',
      component: AdminHyperlinks,
    },
    {
      path: '/admin/hyperlinks/:hyperlinkId',
      name: 'AdminHyperlink',
      component: AdminHyperlink,
      props: true,
    },
    {
      path: '/admin/links-manager',
      name: 'AdminLinksManager',
      component: AdminLinksManager,
    },
    {
      path: '/admin/links-manager/comp/:companyId',
      name: 'AdminLinksManagerCompany',
      component: AdminLinksManagerCompany,
      props: true,
    },
    {
      path: '/admin/links-manager/coll/:goodiebagId',
      name: 'AdminLinksManagerGoodiebag',
      component: AdminLinksManagerGoodiebag,
      props: true,
    },
    {
      path: '/admin/links-manager/acti/:offerId',
      name: 'AdminLinksManagerOffer',
      component: AdminLinksManagerOffer,
      props: true,
    },

    // PV
    {
      path: '/admin/pv',
      name: 'AdminPVHome',
      component: AdminPVHome,
    },
    {
      path: '/admin/pv/companies',
      name: 'AdminPVCompanies',
      component: AdminPVCompanies,
    },
    {
      path: '/admin/pv/companies/:companyId',
      name: 'AdminPVCompany',
      component: AdminPVCompany,
      props: true,
    },
    {
      path: '/admin/pv/campaigns',
      name: 'AdminPVCampaigns',
      component: AdminPVCampaigns,
    },
    {
      path: '/admin/pv/campaigns/:campaignId',
      name: 'AdminPVCampaign',
      component: AdminPVCampaign,
      props: true,
    },
    {
      path: '/admin/pv/activations',
      name: 'AdminPVActivations',
      component: AdminPVActivations,
    },
    {
      path: '/admin/pv/activations/:activationId',
      name: 'AdminPVActivation',
      component: AdminPVActivation,
      props: true,
    },
    {
      path: '/admin/pv/receipts',
      name: 'AdminPVReceipts',
      component: AdminPVReceipts,
    },
    {
      path: '/admin/pv/receipts/:receiptId',
      name: 'AdminPVReceipt',
      component: AdminPVReceipt,
      props: true,
    },
    {
      path: '/admin/pv/reviews',
      name: 'AdminPVReviews',
      component: AdminPVReviews,
    },
    {
      path: '/admin/pv/chains',
      name: 'AdminPVChains',
      component: AdminPVChains,
    },
    {
      path: '/admin/pv/chains/:chainId',
      name: 'AdminPVChain',
      component: AdminPVChain,
      props: true,
    },
    {
      path: '/admin/pv/retailers',
      name: 'AdminPVRetailers',
      component: AdminPVRetailers,
    },
    {
      path: '/admin/pv/retailers/:retailerId',
      name: 'AdminPVRetailer',
      component: AdminPVRetailer,
      props: true,
    },
    {
      path: '/admin/pv/stores',
      name: 'AdminPVStores',
      component: AdminPVStores,
    },
    {
      path: '/admin/pv/stores/:storeId',
      name: 'AdminPVStore',
      component: AdminPVStore,
      props: true,
    },
    {
      path: '/admin/pv/flagged',
      name: 'AdminPVFlagged',
      component: AdminPVFlagged,
    },
    {
      path: '/admin/pv/blocked',
      name: 'AdminPVBlocked',
      component: AdminPVBlocked,
    },

    // Company views
    {
      path: '/partner',
      name: 'CompanyHome',
      component: CompanyHome,
    },
    {
      path: '/new-company',
      name: 'CompanyLink',
      component: CompanyLink,
    },
    {
      path: '/c-activations/:activationId',
      name: 'CompanyActivation',
      component: CompanyActivation,
      props: true,
    },
    {
      path: '/c-collections/:collectionId',
      name: 'CompanyCollection',
      component: CompanyCollection,
      props: true,
    },
    {
      path: '/c-collections',
      name: 'CompanyCollections',
      component: CompanyCollections,
    },
    {
      path: '/c-e',
      name: 'CompanyCompany',
      component: CompanyCompany,
    },
    {
      path: '/c-c/:goodiebagId',
      name: 'CompanyGoodiebag',
      component: CompanyGoodiebag,
      props: true,
    },
    {
      path: '/c-a/:offerId',
      name: 'CompanyOffer',
      component: CompanyOffer,
      props: true,
    },
    {
      path: '/c-drafts',
      name: 'CompanyDrafts',
      component: CompanyDrafts,
    },
    {
      path: '/c-archived',
      name: 'CompanyArchived',
      component: CompanyArchived,
    },
    {
      path: '/c-dashboard',
      name: 'CompanyDashboard',
      component: CompanyDashboard,
    },
    {
      path: '/c-users/list/:companyId',
      name: 'CompanyUsers',
      component: CompanyUsers,
      props: true,
    },
    {
      path: '/c-users/:userId',
      name: 'CompanyUser',
      component: CompanyUser,
      props: true,
    },
    {
      path: '/c-dashboard/collections/:collectionId',
      name: 'CompanyDashboardCollection',
      component: CompanyDashboardCollection,
      props: true,
    },
    {
      path: '/c-dashboard/activations/:activationId',
      name: 'CompanyDashboardActivation',
      component: CompanyDashboardActivation,
      props: true,
    },
    {
      path: '/c-settings/:companyId',
      name: 'CompanySettings',
      component: CompanySettings,
      props: true,
    },
    {
      path: '/c-support',
      name: 'CompanySupport',
      component: CompanySupport,
    },

    /**
     * Redirect to home if it does not find the route
    */
    {
      path: '*',
      name: 'default',
      redirect: { name: 'UserStart' },
    },
  ])),
});

// See Vue Router issue https://github.com/vuejs/vue-router/issues/2110
// Ensure that optional :market route param, as root route, is preserved when navigating (if present in the url before navigation)
// modified workaround found here: https://github.com/vuejs/vue-router/issues/2110#issuecomment-1177700932
// see also customVueRouter.js
router.beforeEach((to, from, next) => {
  // Append the :market param to the 'to' route if it exists on the 'from' route.
  if (from.params && from.params.market) {
    if (to.params)
      to.params.market = from.params.market;
    else
      to.params = { market: from.params.market };
  }
  // Force navigation with a defined :market param if the currently resolved path doesn't include it
  if (to.params && to.params.market
    && to.fullPath != `/${to.params.market}`
    && !to.fullPath.startsWith(`/${to.params.market}/`)) {
    next({
      name: to.name,
      hash: to.hash,
      params: to.params,
      query: to.query,
    });
  } else {
    next();
  }
});

export default router;
