<template>
  <div v-if="loadedPage" class="receipt-description">
    <div class="receipt-image-wrapper">
      <div
        :class="{
          rotate90: rotate == 1,
          rotate180: rotate == 2,
          rotate270: rotate == 3,
        }">
        <!--
        :src="fe_media.file" CORS ISSUE for pdf's

        some browsers and OS's have an issue with v-show on pdf's
        v-show="imageLoaded"
        -->
        <vuePdf
          v-if="receipt.media && receipt.media.fileType == 'application/pdf'"
          class="pdf-component"
          :page="pageNr"
          :src="`${windowOrigin}/api/pv/nar/file/${receipt.media.file}`"
          @loaded="imageLoaded = true"
          @num-pages="pageCount = $event"></vuePdf>
        <img
          v-show="imageLoaded"
          v-else-if="fe_media"
          class="receipt-image"
          :src="fe_media.file" alt=""
          @load="imageLoaded = true"/>

        <div
          v-show="!imageLoaded"
          class="loading-text">
          Loading image <span class="dot-1">.</span><span class="dot-2">.</span><span class="dot-3">.</span>
        </div>
      </div>
      <div class="button-wrapper">
        <button @click="rotate <= 2 ? (rotate += 1) : (rotate = 0)">
          rotate90
        </button>
        <button @click="showEditModal = !showEditModal">
          Edit receipt
        </button>
        <button
          v-if="receipt.confirmed_registration"
          @click="showResetRegistrationConfirmModal = !showResetRegistrationConfirmModal">
          Decline registration
        </button>
        <button
          v-if="activation.cashback_step"
          @click="showCashbackModal = !showCashbackModal">
          Cashback
        </button>
        <span v-if="receipt.comments.length" style="padding: 3px 5px; margin-right: 10px;" :style="!receipt.comments.length ? { 'background': '#ccc'} : { 'background': '#EA7801'}">
          Comments {{
            receipt.comments && receipt.comments.length > 0
              ? `(${receipt.comments.length})`
              : ""
          }}
        </span>
        <button
          v-if="activation.cta_actions && activation.cta_actions.length"
          @click="showActionsModal = true">
          Actions
        </button>
        <button v-if="receipt.flagged" @click="showResetFlagModal = !showResetFlagModal">
          Reset flag
        </button>
        <button @click="showCloneConfirmModal = !showCloneConfirmModal">
          Clone
        </button>
        <button v-if="fe_media && fe_media.file" @click="downloadImage(fe_media.file)">Download Image</button>
        <button @click="deleteModal = !deleteModal">
          Delete
        </button>
        <button v-if="!isBlocked" @click="blockUser()">Block</button>
        <button v-else @click="unblockUser()">Unblock</button>
        <a style="padding-block: 2px;
          padding-inline: 6px;
          border-width: 2px;
          border-style: outset;
          border-color: buttonborder;
          border-image: initial;
          background: rgb(238, 238, 238)"
          :href="`${windowOrigin}/admin/pv/activations/${this.activation._id}`">
          Goto Activation
        </a>
        <div
          v-if="blocked.length && blocked[0].phone_nr == receipt.phone_nr"
          class="blocked-user">
          The user is blocked everywhere by phone_nr!
        </div>
      </div>
    </div>
    <div class="receipt-status">
      <div class="pv-breadcrumb">
        <div class="pv-breadcrumb__container">
          <span v-if="activation.receipt_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 0, 'current' : stepNumber == 0 }">
            <div class="pv-breadcrumb__title">PV</div>
          </span>
          <span v-if="activation.registration_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 1, 'current' : stepNumber == 1 }">
            <div class="pv-breadcrumb__title">PR</div>
          </span>
          <span v-if="activation.survey_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 2, 'current' : stepNumber == 2 }">
            <div class="pv-breadcrumb__title">Survey</div>
          </span>
          <span v-if="activation.address_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 3, 'current' : stepNumber == 3 }">
            <div class="pv-breadcrumb__title">Address</div>
          </span>
          <span v-if="activation.review_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 4, 'current' : stepNumber == 4 }">
            <div class="pv-breadcrumb__title">Review</div>
          </span>
          <span v-if="activation.cashback_step" class="pv-breadcrumb__item" :class="{'visited' : stepNumber > 5, 'current' : stepNumber == 5 }">
            <div class="pv-breadcrumb__title">Cashback</div>
          </span>
          <span class="pv-breadcrumb__item" :class="{ 'visited' : stepNumber == 6 }">
            <div class="pv-breadcrumb__title">Done</div>
          </span>
        </div>

        <div
          v-if="!receipt.cta_done && receipt.receipt_issue && receipt.receipt_issue != 'none'"
          style="color: red; font-weight: bold;">{{ receipt.receipt_issue }}</div>
        
        <div
          v-if="!activation.public"
          style="color: red; font-weight: bold;">The activation is not public!</div>
      </div>

      <Collapsable :collapsed="false" :header="{bgColor: '#F9D7B3', textPosition: 'left'}">
        <template v-slot:header>
          <h3>Receipt details</h3>
        </template>

        <template v-slot:content>
          <div class="collapsable-body">
            <div
              v-if="receipt.status"
              style="margin: 10px 0; font-weight: bold;">
              <span>Status: </span><span style="color: blue;">{{ receipt.status }}</span>
            </div>

            <h3>Line items from receipt</h3>
            <div v-for="(item, index) in receiptKeywordItems" :key="item">
              <div>
                <span>{{index+1}}. </span><span class="select-whole-text">{{ item }}</span>
              </div>
            </div>

            <div v-if="receipt.receipt_date" class="header"><b>receipt_date:</b> {{ receipt.receipt_date }}</div>
            <div v-if="receipt.receipt_analyze_attempts" class="header"><b>receipt_analyze_attempts:</b> {{ receipt.receipt_analyze_attempts }}</div>
            <div v-if="receipt.running_analyzer" class="header" style="color: red;"><b>running_analyzer!</b></div>

            <div v-if="receipt.similar_receipts && receipt.similar_receipts.length">
              <h3>Similar receipts</h3>
              <div class="collapsable-bodys">
                <div
                  v-for="similarReceipt in receipt.similar_receipts"
                  :key="similarReceipt._id"
                  class="select-whole-text"
                  :style="`color: ${similarReceipt.similarity >= activation.receipt_similarity ? 'red' : ''}`">
                  <b>{{ similarReceipt.receipt }}:</b> {{ similarReceipt.similarity }}%
                </div>
              </div>
            </div>

            <div v-if="activation.review_step">
              <h3>Review Attempts</h3>
              <div class="collapsable-bodys">
                <div
                  v-for="reviewAttempt in receipt.review_attempts"
                  :key="reviewAttempt._id"
                  class="select-whole-text">
                  <span>
                    <b>Nickname: </b> {{ reviewAttempt.nickname }}
                  </span>
                  <span>
                    <b>Title: </b> {{ reviewAttempt.title }}
                  </span>
                  <span>
                    <b>Description: </b> {{ reviewAttempt.description }}
                  </span>
                  <div>
                    <b>Appropriate Language: </b> {{ reviewAttempt.appropriateLanguage }}
                  </div>
                  <div>
                    <b>Eelevance To The Product: </b> {{ reviewAttempt.relevanceToTheProduct }}
                  </div>
                  <div>
                    <b>Readability: </b> {{ reviewAttempt.readability }}
                  </div>
                  <div>
                    <b>Summary: </b> {{ reviewAttempt.summary }}
                  </div>
                </div>
              </div>
              <div
                v-if="receipt.review_attempts && receipt.review_attempts.length"
                class="header"
                :style="`color: ${receipt.review_attempts.length >= activation.review_moderation_attempts ? 'red' : ''};`">
                <b>Review attempts:</b> {{ receipt.review_attempts.length }}
              </div>
            </div>
          </div>
        </template>
      </Collapsable>

      <Collapsable :collapsed="false" :header="{bgColor: '#F9D7B3', textPosition: 'left'}">
        <template v-slot:header>
          <h3>Activation details</h3>
        </template>

        <template v-slot:content>
          <div class="collapsable-body">
            <div class="header"><b>Name:</b> {{ activation.name }}</div>
            <div class="header"><b>Info:</b> {{ activation.info }}</div>
            <div v-if="activation.campaign_start_at || activation.campaign_end_at">
              <h3>Campaign date</h3>
              {{ formatDate(activation.campaign_start_at) }}
              - {{ formatDate(activation.campaign_end_at) }}</div>

            <div v-if="activation.receipt_shop_exclude_list && activation.receipt_shop_exclude_list.length">
              <h3>Rejected retailers</h3>
              <div class="collapsable-bodys">
                <div
                  v-for="shopTerm in activation.receipt_shop_exclude_list"
                  :key="shopTerm"
                  class="select-whole-text">{{ shopTerm }}</div>
              </div>
            </div>
            <div v-else-if="combinedShopTerms.length">
              <h3>Accepted retailers</h3>
              <div class="collapsable-bodys">
                <div
                  v-for="shopTerm in combinedShopTerms"
                  :key="shopTerm"
                  class="select-whole-text">{{ shopTerm }}</div>
              </div>
            </div>

            <div v-if="activation.receipt_date_range_from || activation.receipt_date_range_to">
              <h3>Date range</h3>
              {{ formatDate(activation.receipt_date_range_from) }}
              - {{ formatDate(activation.receipt_date_range_to) }}
            </div>

            <div v-if="activation.receipt_step">
              <h3>Terms from activation</h3>
              <div v-for="(items, key) in allReceiptTermsByKey" :key="`pv-${key}`">
                <div>
                  <b>key: {{ key }}</b>
                  <div class="collapsable-bodys">
                    <div
                      v-for="item in items"
                      :key="item"
                      class="select-whole-text">
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="activation.receipt_term_converter">
              <h3>Receipt term converter</h3>
              <span class="collapsable-bodys">
                <span v-for="(item, key) in activation.receipt_term_converter" :key="`converted-${key}`">
                  <b>{{ key }}: </b>
                  <span>{{ item }}</span>
                </span>
              </span>
            </div>

            <div v-if="activation.products && activation.products.length">
              <h3>Products</h3>
              <div class="collapsable-bodys">
                <div
                  v-for="product in activation.products"
                  :key="product"
                  class="select-whole-text">
                  {{ product }}
                </div>
              </div>
            </div>

            <div v-if="activation.receipt_date_max_days" class="header"><b>Date max days old:</b> {{ activation.receipt_date_max_days }}</div>
            <div v-if="activation.receipt_term_minimum_amount" class="header"><b>term minimum amount:</b> {{ activation.receipt_term_minimum_amount }}</div>
            <div class="header"><b>Receipt similarity limit:</b> {{ activation.receipt_similarity }}%</div>

            <div class="divider"></div>

            <div v-if="activation.registration_step">
              <h3>Registration modelnrs</h3>
              <div class="collapsable-bodys">
                <div v-for="(items, key) in allRegistrationTermsByKey" :key="`pr-${key}`">
                  <div v-if="items.serial" class="header"><b>Asking for serial</b></div>
                  <div v-if="items.imei" class="header"><b>Asking for imei</b></div>
                  <div v-if="items.method" class="header"><b>Method:</b> {{ items.method }}</div>
                  <div>
                    <b>key: {{ key }}</b>
                    <div class="collapsable-bodys">
                      <div
                        v-for="item in items.modelnrs"
                        :key="item"
                        class="select-whole-text">
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Collapsable>

      <Collapsable
        :collapsed="true"
        :header="{bgColor: '#F9D7B3', textPosition: 'left'}">
        <template v-slot:header>
          <h3>Steps</h3>
        </template>

        <template v-slot:content>
          <div class="collapsable-body">
            <div class="collapsable-bodys">
              <div
                v-for="(step, index) in receipt.steps"
                :key="step._id">
                <div>{{ index + 1 }}</div>
                <div>Role: {{ step.role }}</div>
                <div>Step type: {{ step.step_type }}</div>
                <div>Step action: {{ step.action }}</div>
                <div>Created at: {{ formatDate(step.created_at) }}</div>
              </div>
            </div>
          </div>
        </template>
      </Collapsable>

      <Collapsable
        :collapsed="true"
        :header="{bgColor: !fe_receipt.comments.length ? '#F9D7B3' : '#EA7801', textPosition: 'left'}">
        <template v-slot:header>
          <h3>Comments ({{ sortedComments.length }})</h3>
        </template>

        <template v-slot:content>
          <div class="collapsable-body">
            <div style="display: flex; flex-direction: row;">
              <md-field>
                <label>Add new comment</label>
                <md-textarea v-model="newComment" md-autogrow></md-textarea>
              </md-field>
              <div
                style="display: flex; flex-direction: column; justify-content: end; margin-bottom: 20px;">
                <button
                  class="btn btn-activate"
                  @click="addAdminReceiptComment()"
                  :disabled="newComment.length < 3">
                  Save
                </button>
              </div>
            </div>

            <div
              v-for="comment in sortedComments"
              :key="comment._id"
              class="comment"
              style="display: flex; flex-direction: column;">
              <span class="date"> {{ formatDate(comment.created_at) }} </span>
              <span>{{ comment.name }}</span>
              {{ comment.comment }}
            </div>
          </div>
        </template>
      </Collapsable>

      <Collapsable
        :collapsed="true"
        :header="{bgColor: '#F9D7B3', textPosition: 'left'}">
        <template v-slot:header>
          <h3>JSON</h3>
        </template>

        <template v-slot:content>
          <div class="collapsable-body">
            <pre class="receipt-metadata" id="json"></pre>
          </div>
        </template>
      </Collapsable>
    </div>

    <modal v-if="showCtaMailModal">
      <h3>CTA email</h3>

      <div v-if="activation && activation._id">
        <div>Email: {{ receipt.email }}</div><br />
        <div>Subject: {{ activation.cta_email_subject }}</div>
        <div v-html="activation.cta_email_template"></div>
      </div>

      <div>
        <button
          @click="sendCtaEmail()"
          class="btn btn-activate">Send CTA Email</button>
        <button
          class="btn btn-transparent"
          @click="showCtaMailModal = !showCtaMailModal">
          Close
        </button>
      </div>
    </modal>

    <modal v-if="showCashbackModal">
      <h3>Confirm / Decline cashback</h3>
      <div>
        <p>Cashback status: {{ receipt.confirmed_cashback }}</p>
        <p v-if="receipt.confirmed_cashback">
          Decline cashback ONLY if we have confirmation from CDI that payment didn't go through.
          This will allow customer to try again with our cashback-flow.
        </p>
        <p v-else-if="!receipt.confirmed_cashback">
          Confirm cashback only if the customer has received money from CDI manually!
          This will remove possibility for customer to use our cashback-flow.
        </p>
      </div>
      <div>
        <button
          class="btn btn-activate"
          @click="confirmCashback()">{{ receipt.confirmed_cashback ? 'Decline cashback' : 'Confirm cashback' }}
        </button>
        <button class="btn btn-transparent" @click="showCashbackModal = !showCashbackModal">Cancel</button>
      </div>
    </modal>

    <modal v-if="showCloneConfirmModal">
      <h3>Are you sure you want to clone this receipt?</h3>
      <div>
        <p>You can change the line item and price for the new cloned receipt</p>
      </div>
      <div>
        <button
          class="btn btn-transparent"
          @click="showCloneConfirmModal = !showCloneConfirmModal">
          Close
        </button>
        <button
          class="btn btn-transparent"
          @click="onCloneConfirm">
          Clone please
        </button>
      </div>
    </modal>

    <modal v-if="showEditModal">
      <h3>Edit receipt</h3>
      <div style="max-width: 600px;">
        <p>Edit and override these fields</p>
        <div style="display: flex; flex-direction: column;">
          <inputField
            v-model="editForm.receipt_store"
            style="margin: 5px 0;"
            label="Retailer"/>
          <inputField
            v-model="editForm.receipt_date"
            style="margin: 5px 0;"
            label="Date"
            placeholder="YYYY-MM-DD"/>
          <div
            v-if="receipt.receipt_issue && receipt.receipt_issue.includes('range')"
            style="color: red; font-weight: bold;">The date is not inside the range</div>
          <inputField
            v-if="activation.review_step"
            v-model="editForm.review_attempts_additional"
            style="margin: 5px 0;"
            label="Additional review attempts"/>
          <inputField
            v-model="editForm.confirmed_receipt"
            style="margin: 5px 0;"
            label="Receipt confirmed"
            inputType="checkbox"/>
          <inputField
            v-model="editForm.confirmed_registration"
            style="margin: 5px 0;"
            label="Registration confirmed"
            inputType="checkbox"/>
          <inputField
            v-model="editForm.running_analyzer"
            style="margin: 5px 0;"
            label="Running analyzer"
            inputType="checkbox"/>
          <div
            v-if="receipt.status"
            style="margin: 10px 0; font-weight: bold;">
            <span>Status: </span><span style="color: blue;">{{ receipt.status }}</span>
          </div>
          <div
            v-if="!receipt.cta_done && receipt.receipt_issue && !receipt.receipt_issue.includes('range') && receipt.receipt_issue != 'none'"
            style="color: red; font-weight: bold;">{{ receipt.receipt_issue }}</div>
          <button class="btn btn-activate" @click="addReceiptItem()">Add item</button>
          <button class="btn btn-transparent" @click="removeReceiptItem()">Remove item</button>
          <Collapsable :collapsed="true" :header="{bgColor: '#F9D7B3', textPosition: 'left'}">
            <template v-slot:header>
              <h3>Details</h3>
            </template>

            <template v-slot:content>
              <div class="collapsable-body">
                <div v-if="activation.receipt_shop_exclude_list && activation.receipt_shop_exclude_list.length">
                  <h3>Rejected retailers</h3>
                  <div class="collapsable-bodys">
                    <div
                      v-for="shopTerm in activation.receipt_shop_exclude_list"
                      :key="shopTerm"
                      class="select-whole-text">{{ shopTerm }}</div>
                  </div>
                </div>
                <div v-else-if="combinedShopTerms.length">
                  <h3>Accepted retailers</h3>
                  <div class="collapsable-bodys">
                    <div
                      v-for="shopTerm in combinedShopTerms"
                      :key="shopTerm"
                      class="select-whole-text">{{ shopTerm }}</div>
                  </div>
                </div>

                <div v-if="activation.receipt_date_range_from || activation.receipt_date_range_to">
                  <h3>Date range</h3>
                  {{ formatDate(activation.receipt_date_range_from) }}
                  - {{ formatDate(activation.receipt_date_range_to) }}
                </div>

                <h3>Terms from activation</h3>
                <div v-for="(items, key) in allReceiptTermsByKey" :key="`term-${key}`">
                  <div>
                    <b>key: {{ key }}</b>
                    <div class="collapsable-bodys">
                      <div
                        v-for="item in items"
                        :key="item"
                        class="select-whole-text">
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>

                <h3>Line items from receipt</h3>
                <div v-for="(item, index) in receiptKeywordItems" :key="item">
                  <div>
                    <span>{{index+1}}. </span><span class="select-whole-text">{{ item }}</span>
                  </div>
                </div>
              </div>
            </template>
          </Collapsable>
          <div
            v-for="(item, index) in editReceiptItems"
            :key="`receipt-item-${index}`"
            class="line-item">
            <inputField
              v-model="item.item"
              style="margin: 5px 0;"
              label="Item"/>
            <inputField
              v-model="item.item_cost"
              style="margin: 5px 0;"
              label="Cost"/>
            <div class="item-match-inputs">
              <inputField
                v-model="item.item_match"
                @input="changeItemMatch($event, item)"
                style="margin: 5px 0;"
                label="Item Match"/>
              <div
                v-if="!allReceiptTerms.includes(item.item_match)"
                style="margin: 5px 0 5px 10px;">Adding it to terms</div>
              <div
                v-else
                style="margin: 5px 0 5px 10px;">Already in terms</div>
              <inputField
                v-model="item.item_match_converted"
                @input="changeItemMatchConverted($event, item)"
                style="margin: 5px 0 5px 0;"
                label="Converted term"/>
              <inputField
                v-if="showAddToProducts(item)"
                v-model="item.item_match_add_product"
                @input="setOtherSteps(item)"
                inputType="checkbox"
                style="margin: 5px 0 5px 10px;"
                label="Add to products?"/>
              <div
                v-if="activation.products.includes(item.item_match)"
                style="margin: 5px 0 5px 10px;">Term already in products</div>
              <div
                v-else-if="activation.products.includes(item.item_match_converted)"
                style="margin: 5px 0 5px 10px;">Converted term already in products</div>
              <inputField
                v-if="activation.review_step && item.item_match_add_product"
                v-model="item.item_match_review_product"
                style="margin: 5px 0 5px 10px;"
                inputType="textarea"
                label="Review Config"/>
              <inputField
                v-if="activation.cashback_step && item.item_match_add_product"
                v-model="item.item_match_cashback_amount"
                style="margin: 5px 0 5px 10px;"
                inputType="textarea"
                label="Cashback amount"/>
              <div v-if="activations && activations.length && (!allReceiptTerms.includes(item.item_match) || showAddToProducts(item))">
                <inputField
                  v-model="item.item_match_add_to_activations"
                  inputType="multi-select"
                  :selectOptions="activations"
                  :selectSettings="{ optionTitle: 'name', optionKey: '_id', optionValue: '_id' }"
                  style="margin: 5px 0 5px 10px;"
                  label="Add to other activations"/>
                <div
                  v-if="item.item_match_add_to_activations.length != activations.length"
                  style="color: red; font-weight: bold;">
                  selected: {{ item.item_match_add_to_activations.length }} / {{ activations.length }}
                </div>
              </div>
              <div v-else-if="(!activations || !activations.length) && (!allReceiptTerms.includes(item.item_match) || showAddToProducts(item))">
                No related activations
              </div>
            </div>
            <inputField
              v-model="item.serialnr"
              style="margin: 5px 0;"
              label="Registration serialnr"/>
            <inputField
              v-model="item.imeinr"
              style="margin: 5px 0;"
              label="Registration imeinr"/>
            <inputField
              v-model="item.modelnr"
              style="margin: 5px 0;"
              label="Registration modelnr"/>
            <InputField
              label="Key (be carefull)"
              inputType="select"
              :selectOptions="activationReceiptKeys"
              v-model="item.key"/>
          </div>
        </div>
      </div>
      <div>
        <button
          class="btn btn-transparent"
          @click="showEditModal = !showEditModal">
          Close
        </button>
        <button
          class="btn btn-transparent"
          @click="onEditConfirm">
          Save
        </button>
      </div>
    </modal>

    <modal v-if="showResetRegistrationConfirmModal">
      <h3>Are you sure you want to decline confirmed registration?</h3>

      <div>
        <div>
          The receipt field confirmed_registration will be set to false 
          in order to unlock the registration step.
        </div>

        <div style="margin-top: 30px; color: red;">
          <inputField
            v-model="alsoBlock"
            style="margin: 10px 0;"
            label="Would you also want to block the user?"
            inputType="checkbox"/>
        </div>
        <!--
        <inputField
          v-model="requirePRChange"
          style="margin: 10px 0;"
          label="Block the current PR settings, so it can't be used again"
          inputType="checkbox"/>-->
      </div>

      <div>
        <button
          class="btn btn-transparent"
          @click="showResetRegistrationConfirmModal = !showResetRegistrationConfirmModal">
          Close
        </button>
        <button
          class="btn btn-activate"
          @click="onResetRegistrationConfirm()">
          Reset
        </button>
      </div>
    </modal>

    <modal v-if="showResetFlagModal"
      :header="{closeButton: true, text: 'Reset flag'}"
      @close="showResetFlagModal = false">
      <slot>
        <div>
          <h3>Are you sure you want to remove flag for this receipt?</h3>
          <div>
            The receipt will no longer show up in the flagged inbox
          </div>
        </div>
        <button
          class="btn btn-transparent"
          @click="showResetFlagModal = false">
          Cancel
        </button>
        <button
          class="btn btn-activate"
          @click="onResetFlagConfirm()">
          Remove flag
        </button>
      </slot>
    </modal>

    <modal
      v-if="showActionsModal"
      :header="{closeButton: true, text: 'Actions'}"
      @close="showActionsModal = false">
      <table>
        <thead>
          <tr>
            <th>Action ID</th>
            <th>Executed</th>
            <th>Type</th>
            <th>Offer ID</th>
            <th>Collection ID</th>
            <th>Delayed Duration</th>
            <th>Trigger</th>
            <th>Payload</th>
            <th>Endpoint</th>
            <th>Points</th>
            <th>Email Subject</th>
            <th>Email Template</th>
            <th>File Attachment filename</th>
            <th>Buttons</th>
            <!--<th>File Attachment file key</th>
            <th>File Attachment file type</th>
            <th>File Attachment field positions</th>-->
          </tr>
        </thead>
        <tbody>
          <tr v-for="action in mergedCtaActions" :key="action._id">
            <td>{{ action._id }}</td>
            <td :style="`color: ${action.executed ? 'green' : 'red'};`">{{ action.executed ? 'yes' : 'no' }}</td>
            <td>{{ action.type }}</td>
            <td>{{ action.offer_id }}</td>
            <td>{{ action.collection_id }}</td>
            <td>{{ action.delayed_duration }}</td>
            <td>{{ action.trigger }}</td>
            <td>{{ action.payload }}</td>
            <td>{{ action.endpoint }}</td>
            <td>{{ action.points }}</td>
            <td>{{ action.email_subject }}</td>
            <td>{{ action.email_template != '' ? 'yes' : 'no' }}</td>
            <td>{{ action.file_attachment.filename }}</td>
            <td>
              <button class="btn btn-activate" @click="forceRunAction(action._id)">Force Run</button>
            </td>
            <!--<td>{{ action.file_attachment.fileKey }}</td>
            <td>{{ action.file_attachment.fileType }}</td>
            <td>{{ action.file_attachment.fieldPositions }}</td>-->
          </tr>
        </tbody>
      </table>
      <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 10px;">
        <button class="btn btn-transparent" @click="showActionsModal = false">Cancel</button>
        <button
          class="btn btn-activate"  
          @click="blockDelayedCta()">
          {{ receipt.cta_actions_block ? 'Remove Actions block' : 'Block Actions' }}
        </button>
        <button class="btn btn-activate" @click="runEligbleActions()">Run all eligible actions</button>
      </div>
    </modal>

    <modal v-if="deleteModal">
      <h3>Du you really want to delete the receipt?</h3>
      <p v-if="receipt.cta_done">The receipt has CTA_DONE, deleting the receipt means that the consumer can get another Prize</p>
      <p v-else>The receipt is not yet done with all the steps</p>
      <div>
        <InputField
          label="Delete code"
          placeholder="****"
          v-model="deleteCode"/>
      </div>
      <div>
        <button class="btn btn-transparent" @click="deleteModal = false">Cancel</button>
        <button class="btn btn-activate" @click="deleteReceipt()">Delete</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { map } from 'lodash';
import moment from 'moment-timezone';
import EventBus from '../resources/eventBus';
import vuePdf from 'vue-pdf';
import BeforeEnterMixin from '../mixins/BeforeEnterMixin.vue';
import receiptApi from '../api/pvReceipt.api';
import InputField from '../stories/components/InputField/InputField.vue';
import Collapsable from '../stories/components/Collapsable/Collapsable.vue';

export default {
  name: 'AdminPVReceipt',
  components: {
    vuePdf,
    InputField,
    Collapsable,
  },
  mixins: [BeforeEnterMixin],
  props: ['receiptId'],
  mounted() {
    this.getAdminReceipt();
  },
  computed: {
    ...mapGetters('pvReceipts', ['receipt']),
    ...mapGetters('pvActivations', ['receipts', 'activations']),
    ...mapGetters('pvBlocked', ['blocked']),
    activation() {
      return this.receipt.activation;
    },
    sortedComments() {
      return this.fe_receipt && this.fe_receipt.comments ? this.fe_receipt.comments
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        : [];
    },
    anyOpenModal() {
      return this.showForceConfirmModal
        || this.showCtaMailModal
        || this.showCashbackModal
        || this.showResetRegistrationConfirmModal
        || this.showCloneConfirmModal
        || this.showResetFlagModal
        || this.showEditModal
        || this.showActionsModal
        || this.deleteModal;
    },
    combinedShopTerms() {
      let combinedShopTerms = [];
      if (this.receipt && this.activation) {
        let retailerTerms = this.activation.receipt_retailer_list.map(retailer => retailer.terms);
        retailerTerms = [].concat.apply([], retailerTerms);
        combinedShopTerms = this.activation.receipt_shop_list.concat(retailerTerms);
      }
      return combinedShopTerms;
    },
    mergedCtaActions() {
      let ctaActions = [];
      if (this.receipt && this.activation) {
        ctaActions = this.activation.cta_actions.map(action => {
          const runAction = this.receipt.cta_executed_actions.find(executedAction => executedAction.action === action._id);
          return {
            ...action,
            executed: !!runAction?._id,
          };
        });
      }
      return ctaActions;
    },
    activationReceiptKeys() {
      return this.activation.receipt_terms_multiple.map(term => term.key);
    },
    receiptKeywordItems() {
      const lineItems = map(this.receipt?.receipt_metadata?.Items?.valueArray || []);
      const receiptKeywordItems = [];
      
      lineItems.map(lineItem => {
        const { Description } = lineItem.valueObject;
        const content = lineItem.content;

        let descriptionTitle;
        let contentTitle;

        if (Description) {
          descriptionTitle = (Description.content || Description.valueString).replace(/\n/g, " ").toLowerCase();
          receiptKeywordItems.push(descriptionTitle);
        }
        if (content) {
          contentTitle = content.replace(/\n/g, " ").toLowerCase();
          if (!descriptionTitle || descriptionTitle !== contentTitle) {
            receiptKeywordItems.push(contentTitle);  
          }
        }
      });

      return receiptKeywordItems;
    },
    allReceiptTermsByKey() {
      const receiptTermsMultiple = this.receipt?.activation.receipt_terms_multiple || [];

      const allReceiptTermsByKey = receiptTermsMultiple.reduce((acc, curr) => {
        if (!acc[curr.key]) {
          acc[curr.key] = [];
        }
        acc[curr.key].push(...curr.terms);
        return acc;
      }, {});

      return allReceiptTermsByKey;
    },
    allReceiptTerms() {
      const receiptTermsMultiple = this.receipt?.activation.receipt_terms_multiple || [];

      const allReceiptTerms = receiptTermsMultiple.reduce((acc, curr) => {
        acc.push(...curr.terms);
        return acc;
      }, []);

      return allReceiptTerms;
    },
    allRegistrationTermsByKey() {
      const registrationTermsMultiple = this.receipt?.activation.registration_terms_multiple || [];

      const allRegistrationTermsByKey = registrationTermsMultiple.reduce((acc, curr) => {
        if (!acc[curr.key]) {
          acc[curr.key] = {
            modelnrs: curr.modelnrs,
            method: curr.method,
            serial: curr.serial,
            imei: curr.imei,
          };
        }
        acc[curr.key].modelnrs.push(...curr.modelnrs);
        // If 'method' is a property of 'curr', you can assign it like this:
        // acc[curr.key].method = curr.method;
        return acc;
      }, {});

      return allRegistrationTermsByKey;
    },
    isBlocked() {
      return this.blocked.length;
    },
    stepNumber() {
      let stepNumber = 0;
      const activation = this.activation;
      let theReceipt = this.receipt;
      if (theReceipt.cta_issue) {
        // stepNumber = -1;
      } else if (activation.receipt_step && !theReceipt.confirmed_receipt) {
        stepNumber = 0;
      } else if (activation.registration_step && !theReceipt.confirmed_registration) {
        stepNumber = 1;
      } else if (activation.survey_step && !theReceipt.confirmed_survey) {
        stepNumber = 2;
      } else if (activation.address_step && !theReceipt.confirmed_address) {
        stepNumber = 3;
      } else if (activation.review_step && !theReceipt.confirmed_review) {
        stepNumber = 4;
      } else if (activation.cashback_step && !theReceipt.confirmed_cashback) {
        stepNumber = 5;
      } else {
        stepNumber = 6;
      }

      return stepNumber;
    },
  },
  data() {
    return {
      loadedPage: false,
      showForceConfirmModal: false,
      showResetRegistrationConfirmModal: false,
      showCtaMailModal: false,
      showCashbackModal: false,
      showActionsModal: false,
      deleteModal: false,
      showCloneConfirmModal: false,
      showResetFlagModal: false,
      showEditModal: false,
      pageCount: 0,
      pageNr: 1,
      fe_receipt: {},
      fe_receipts: [],
      theKey: '',
      checkedKeywordItems: [],
      fe_media: null,
      imageLoaded: false,
      rotate: 0,
      currentReceiptId: '',
      deleteCode: '',
      newComment: '',
      alsoBlock: false,
      cloneForm: {},
      editForm: {confirmed_receipt: false},
      editReceiptItems: [],
    };
  },
  methods: {
    async getAdminReceipt(query) {
      await this.$store
        .dispatch('pvReceipts/getAdminReceipt', { receiptId: query || this.receiptId });

      await this.getCampaignActivations();
      await this.findAdminBlocked();
      this.setJsonData();
    },
    getCampaignActivations() {
      return this.$store
        .dispatch('pvActivations/findAdminPVActivation', {
          query: '',
          notIds: [this.activation._id],
          campaign: this.activation.campaign,
          company: this.activation.company,
        })
    },
    setJsonData() {
      this.fe_media = this.receipt.fe_media;
      this.fe_receipt = JSON.parse(JSON.stringify(this.receipt));
      this.fe_receipt.fe_media = {
        file: 'changed in order to skip long scroll..',
      };
      delete this.fe_receipt.activation;
      this.setReceiptNone('receipt_type');
      this.setReceiptNone('receipt_metadata');
      this.setReceiptNone('receipt_analyze_attempts');
      this.setReceiptNone('receipt_store');
      this.setReceiptNone('receipt_date');
      this.setReceiptNone('receipt_date_unix');
      this.setReceiptNone('receipt_number');
      this.setReceiptNone('receipt_address');
      this.setReceiptNone('receipt_phone');
      this.setReceiptNone('receipt_total');
      this.setReceiptNone('changed_receipt_data');
      this.setReceiptNone('confirmed_receipt');

      this.$set(this, 'editReceiptItems', this.fe_receipt.receipt_items || []);
      for (let i = 0; i < this.editReceiptItems.length; i++) {
        const receiptItem = this.editReceiptItems[i];
        this.changeItemMatch(receiptItem.item_match, receiptItem);
      }
      this.$set(this.editForm, 'receipt_store', this.fe_receipt.receipt_store);
      this.$set(this.editForm, 'receipt_date', this.fe_receipt.receipt_date);
      this.$set(this.editForm, 'confirmed_receipt', this.fe_receipt.confirmed_receipt);
      this.$set(this.editForm, 'confirmed_registration', this.fe_receipt.confirmed_registration);
      this.$set(this.editForm, 'review_attempts_additional', this.fe_receipt.review_attempts_additional);
      this.$set(this.editForm, 'running_analyzer', this.fe_receipt.running_analyzer);

      this.loadedPage = true;
      setTimeout(() => {
        const jsonTag = document.getElementById('json');
        jsonTag.innerHTML = this.syntaxHighlight(JSON.stringify(this.fe_receipt, undefined, 2));
      }, 100);
    },
    setReceiptNone(key) {
      this.fe_receipt[key] = this.fe_receipt[key] === undefined ? null : this.fe_receipt[key];
    },
    syntaxHighlight(json) {
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
        function (match) {
          let cls = "a-number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "a-key";
            } else {
              cls = "a-string";
            }
          } else if (/true|false/.test(match)) {
            cls = "a-boolean";
          } else if (/null/.test(match)) {
            cls = "a-null";
          } else if (match < 3000000000 && match > 1000000000) {
            match = moment.unix(match).format('YYYY-MM-DD HH:mm:ss ZZ');
          }
          return `<span class="${cls}">${match}</span>`;
        }
      );
    },
    sendCtaEmail() {
      receiptApi.sendCtaEmail(this.receiptId)
      .then((response) => {
        this.doAlert(response.message);
        this.showCtaMailModal = false;
      });
    },
    cloneReceipt() {
      this.$store
        .dispatch("pvReceipts/cloneReceipt", {receiptId:this.receiptId})
        .then((clone) => {

          let clonedUrl = window.location.href.replace(/a-r\/.*$/, `a-r/${clone._id}`);
          window.open(`${clonedUrl}`, '_blank');
          this.showCloneConfirmModal = false;
        });
    },
    existsInTerms(itemMatch) {
      const list = this.receipt?.activation?.receipt_terms_multiple || [];
      return list.includes(itemMatch);
    },
    editSaveReceipt() {
      this.$store
        .dispatch("pvReceipts/saveAdminReceipt", {
          receiptId: this.receiptId,
          ...this.editForm,
          receipt_items: this.editReceiptItems,
        }).then(() => {
          this.doAlert('Successfully saved the receipt!');
          this.showEditModal = false;
          this.setJsonData();
        });
    },
    resetFlagReceipt() {
      this.$store
        .dispatch("pvFlagged/resetFlagForReceipt", this.receiptId)
        .then(() => {
          this.showResetFlagModal = false;
          this.getAdminReceipt();
        });
    },
    resetRegistration() {
      this.$store
      .dispatch("pvReceipts/confirmAdminRegistration", {
        receiptId: this.receiptId,
        confirm: false,
      })
      .then(() => {
        this.showResetRegistrationConfirmModal = false;
        this.setJsonData();
      });

      if (this.alsoBlock) {
        this.blockUser();
      }
    },
    addAdminReceiptComment() {
      this.$store
      .dispatch("pvReceipts/addAdminReceiptComment", {
        comment: this.newComment,
        entityId: this.receiptId
      })
      .then(() => {
        this.newComment = "";
        this.setJsonData();
      });
    },
    async forceRunAction(actionId) {
      await this.$store.dispatch('pvReceipts/forcePVAction', { receiptId: this.receipt._id, actionId });
      this.doAlert('Forcefully executed action');
    },
    async runEligbleActions() {
      await this.$store.dispatch('pvReceipts/testPVActions', { receiptId: this.receipt._id });
      this.doAlert('Eligble actions executed');
    },
    confirmCashback() {
      this.$store
      .dispatch("pvReceipts/confirmAdminCashback", { confirm: !this.receipt.confirmed_cashback, entityId: this.receiptId })
      .then(() => {
        this.setJsonData();
      });
    },
    findAdminBlocked() {
      return this.$store.dispatch('pvBlocked/findAdminBlocked', {
        phoneNr: this.receipt.phone_nr,
        email: this.receipt.email,
      });
    },
    blockUser() {
      this.$store.dispatch('pvBlocked/addAdminBlock', {
        phoneNr: this.receipt.phone_nr,
        email: this.receipt.email,
      }).then(() => {
        this.alsoBlock = false;
        this.findAdminBlocked();
      });
    },
    unblockUser() {
      this.$store.dispatch('pvBlocked/deleteAdminBlock', {
        blockId: this.blocked[0]._id,
      }).then(() => {
        this.alsoBlock = false;
        this.findAdminBlocked();
      });
    },
    blockDelayedCta() {
      this.$store
      .dispatch("pvReceipts/addAdminReceiptDelayedCtaBlock", { block: !this.receipt.cta_actions_block, entityId: this.receiptId })
      .then(() => {
        this.setJsonData();
      });
    },
    onCloneConfirm(){
      this.cloneReceipt();
    },
    onResetRegistrationConfirm(){
      this.resetRegistration();
    }, 
    onEditConfirm(){
      this.editSaveReceipt();
    },
    onResetFlagConfirm(){
      this.resetFlagReceipt();
    },
    addReceiptItem() {
      this.editReceiptItems.push({
        item_match_add_to_activations: [],
      });
      this.showEditModal = false;
      this.showEditModal = true;
    },
    removeReceiptItem() {
      this.editReceiptItems.pop();
      this.showEditModal = false;
      this.showEditModal = true;
    },
    changeItemMatch(event, receiptItem) {
      this.$set(receiptItem, 'item_match_add_product', false);
      this.$set(receiptItem, 'item_match_add_to_activations', []);
      const convertedTerm = this.getConvertedTerm(receiptItem);
      if (convertedTerm != receiptItem.item_match) {
        this.$set(receiptItem, 'item_match_converted', convertedTerm);
      } else {
        this.$set(receiptItem, 'item_match_converted', '');
      }
    },
    changeItemMatchConverted(event, receiptItem) {
      this.$set(receiptItem, 'item_match_add_product', false);
    },
    setOtherSteps(receiptItem) {
      const convertedTerm = this.getConvertedTerm(receiptItem);
      if (receiptItem.item_match_add_product) {
        this.$set(receiptItem, 'item_match_review_product', `{ "${convertedTerm}": { "bazaarvoice": "${convertedTerm}", "testfreaks": "${convertedTerm}", "attributes": "", "media": "" } }`);
        this.$set(receiptItem, 'item_match_cashback_amount', `{ "${convertedTerm}": 0 }`);
      } else {
        this.$set(receiptItem, 'item_match_review_product', `{}`);
        this.$set(receiptItem, 'item_match_cashback_amount', `{}`);
      }
    },
    showAddToProducts(receiptItem) {
      if (!this.activation?.products) {
        return true;
      }

      const convertedTerm = this.getConvertedTerm(receiptItem);
      return !this.activation.products.includes(convertedTerm);
    },
    getConvertedTerm(receiptItem) {
      const haveConverter = this.activation?.receipt_term_converter && Object.keys(this.activation.receipt_term_converter).length;
      const convertedTerm = (haveConverter ? this.activation.receipt_term_converter[receiptItem.item_match] : false)
        || receiptItem.item_match_converted
        || receiptItem.item_match;
      return convertedTerm;
    },
    doAlert(message) {
      EventBus.$emit("setAlert", {
        message,
        close: "OK",
        seconds: 4,
        top: 0,
        bot: "unset",
        left: 0,
        right: 0,
      });
    },
    popupLeft() {
      if (this.anyOpenModal) {
        return;
      }
      if (!this.receipts || !this.receipts.length) {
        this.doAlert('There are no receipts to navigate through.');
        return;
      }
      for(let i = 0; i < this.receipts.length; i++) {
        if (this.receipts[i]._id === this.receipt._id) {
          const prevIndex = i-1;
          if (prevIndex >= 0) {
            this.imageLoaded = false;
            this.$router.replace({ params: { receiptId: this.receipts[prevIndex]._id } });
            this.getAdminReceipt(this.receipts[prevIndex]._id);
          } else {
            this.doAlert('You are at the first receipt');
          }
        }
      }
    },
    popupRight() {
      if (this.anyOpenModal) {
        return;
      }
      if (!this.receipts || !this.receipts.length) {
        this.doAlert('There are no receipts to navigate through.');
        return;
      }
      for(let i = 0; i < this.receipts.length; i++) {
        if (this.receipts[i]._id === this.receipt._id) {
          const nextIndex = i+1;
          if (nextIndex <= this.receipts.length-1) {
            this.imageLoaded = false;
            this.$router.replace({ params: { receiptId: this.receipts[nextIndex]._id } });
            this.getAdminReceipt(this.receipts[nextIndex]._id);
          } else {
            this.doAlert('You are at the last receipt');
          }
        }
      }
    },
    handleKeydown (itemId) {
      switch (itemId.keyCode) {
        case 37:
        this.popupLeft();
        break;
        case 39:
        this.popupRight();
        break;
      }
    },
    formatDate(unix) {
      return moment.unix(unix).format("YYYY-MM-DD HH:mm:ss"); //"YYYY-MM-DD HH:mm:ss ZZ"
    },
    async downloadImage(imageUrl) {
      this.openUrl(imageUrl, true);
    },
    deleteReceipt() {
      this.$store.dispatch('pvReceipts/deleteAdminReceipt', {
        receiptId: this.receipt._id,
        deleteCode: this.deleteCode,
      }).then(() => {
        this.setAlert('Successfully deleted receipt!');
        this.$router.go(-1);
      });
    }
  },
  beforeMount () {
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.a-string{
  color: darkorange;
}
.a-number{
  color: lightgreen;
}
.a-boolean{
  color: #7171ff;
}
.a-null{
  color: #c7291b;
}
.a-key{
  color: lightblue;
}

.additional-terms{
  height: 40px;
  .additional-terms-input {
    margin-left: 20px;
  }
}

</style>

<style lang="scss" scoped>
.receipt-description {
  position: absolute;
  text-align: left;
  display: flex;
  height: 100%;
  width: 100%;

  .receipt-image-wrapper {
    position: relative;
    width: 50%;
    height: 100%;
    background-color: black;
    overflow: auto;
    .receipt-image {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: auto;
      margin: auto;
    }
    .button-wrapper {
      position: absolute;
      left: 5px;
      top: 5px;
      button {
        margin-right: 20px;
      }
    }

    .loading-text {
      position: absolute;
      top: 50%;
      left: 40%;
      color: red;
      font-size: 28px;

      @keyframes dot {
        0%, 20% { opacity: 0; }
        50%, 100% { opacity: 1; }
      }
  
      .dot-1 {
        animation: dot 1s infinite;
        animation-delay: 0.2s;
      }
  
      .dot-2 {
        animation: dot 1s infinite;
        animation-delay: 0.4s;
      }
  
      .dot-3 {
        animation: dot 1s infinite;
        animation-delay: 0.6s;
      }
    }
  }
  .receipt-metadata {
    color: white;
    width: 100%;
    height: calc(50vh);
    margin: 0;
    background-color: rgb(37, 37, 37);
  }

  .receipt-status {
    width: 50%;
    height: calc(100vh - $nav-height);
    margin: 0;
    padding: 10px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: lightgrey;
  }
}
.blocked-user{
  position: fixed;
  left: 30%;
  top: 50%;
  color: white;
  background: linear-gradient(blue, red);
  padding: 20px;
  border-radius: 50% 40% 40% 20% / 70% 50% 30% 25%;
}

.rotate90 {
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate180 {
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate270 {
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.pdf-component {
  height: 250px !important;
  canvas {
    height: 250px !important;
  }
}
.date {
  font-size: 12px;
  margin-top: 10px;
  font-weight: 900;
}
.comment {
  border-bottom: 1px solid #cbcbcb;
}

.collapsable-body{
  padding: 10px 15px;
  box-shadow: inset 0 0 5px grey;
  
  h3 {
    margin: 10px 0 5px 0;
  }
  .collapsable-bodys {
    display: flex;
    flex-wrap: wrap;
    gap: 1px 5px;
  }
}
.line-item {
  background: rgb(224, 224, 224);
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
}

.item-match-inputs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #cbcbcb;
}

.item-match-inputs > div {
  flex-basis: 40%;
}

.pv-breadcrumb {
  position: relative;
  padding: 10px 0;
  align-items: center;

  .pv-breadcrumb__container {
    .pv-breadcrumb__item {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #cccccc;
      border-radius: 7px;
      margin: 0 40px;
  
      &:not(:last-child):after {
        content: "";
        height: 2px;
        background: #cccccc;
        opacity: 0.7;
        position: absolute;
        width: 70px;
        margin: 4px 0 0 15px;
      }
      .pv-breadcrumb__title {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    .visited {
      background: green;
  
      &::after {
        background: #666666;
      }
    }
    .current {
      background: blue;
  
      &::after {
        background: #666666;
      }
    }
  }
}
</style>
